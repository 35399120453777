<template>
    <div class="content">
        <div class="title">
             <router-link to="/professors" style="text-decoration: none"> <h1> PROFCHECK </h1> </router-link>
        </div>

        <div class="search">
            <q-select use-input outlined v-model="selectedProfessor" :label="$t('profcheck.search_input')" behavior="menu"
                :options="professorOptions" style="width: 40%" @update:model-value="onUpdate" @filter="filterFn">

                <template v-slot:option="scope">
                    <q-item v-bind="scope.itemProps">
                        <q-item-section>
                            <q-item-label>{{ scope.opt.label }}</q-item-label>
                            <q-item-label caption>{{ scope.opt.institute }}</q-item-label>
                        </q-item-section>
                    </q-item>
                </template>
            </q-select>
        </div>
    </div>
</template>

<script>
import { QSpinnerGears } from 'quasar';
import { ref } from 'vue';
import { useProfStore } from '@/store/prof.store';

export default {
    setup: () => {
        const store = useProfStore();

        return {
            professorList: ref(store.professors),
            professorOptions: ref([]),
            store: ref(store),
            selectedProfessor: ref(null)
        }
    },
    props: {
        redirectToRate: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        filterFn(val, update) {
            update(() => {
                const needle = val.toLowerCase();
                const filter_list = this.professorList.filter(prof => Object.keys(prof).some(key => String(prof[key]).toLowerCase().includes(needle)));

                if (filter_list.length > 0) {
                    this.professorOptions.length = 0;

                    this.professorOptions.push(...filter_list.map(e => {
                        return {
                            label: (e.title ? e.title + ' ' : '') + e.fname + ' ' + e.lname,
                            value: e._id,
                            institute: e.institute
                        }
                    }));
                } else {
                    this.professorOptions = [{
                        label: 'No results found',
                        value: null
                    }];
                }
            })
        },
        onUpdate(e) {
            this.onSelectProfessor(e.value);
        },
        async onSelectProfessor(profId) {
            await this.store.setSelected(profId);
            location.reload();
        },
    },
    async created() {

        if (this.store.professors.length === 0) {
            const notif = this.$q.notify({
                group: false,
                timeout: 0,
                spinner: QSpinnerGears,
                message: 'Loading professors...',
                position: 'bottom',
                color: 'primary',
            });

            try {
                const response = await this.store.fetchProfessors(this.$axios);

                if (response.status === 200) {
                    notif({
                        message: 'Professors loaded.',
                        color: 'positive',
                        spinner: false,
                        timeout: 2000,
                        icon: 'check'
                    });

                    this.professorList = response.data;
                }
            } catch (e) {
                notif({
                    message: 'Error loading professors.',
                    color: 'negative',
                    spinner: false,
                    icon: 'error',
                    timeout: 2000,
                });
            }
        }
    }
}
</script>

<style scoped>
.content {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;
    width: 100%;
    margin-top: 10px;
}

.title {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title h1 {
    font-family: "Staatliches", sans-serif;
    color: #5bbdf4;
    font-size: 3.2em;
    font-weight: 400;
    padding: 0%;
    margin: 0%;
}

.search {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.1em;
    width: 100%;
}

.search input {
    border: none;
    border-radius: 4.78px;
    background-color: #e7f4ff;
    font-size: 0.8em;
    padding: 0.6em;
    width: 100%;
}
</style>