<template>
  <div>
    <ProfessorSearch />

    <img
      src="../assets/profcheck-thumb-kontur.svg"
      id="profcheck-logo"
      alt="profcheck-logo"
    />

    <div class="col-12 row q-pa-md q-mt-xl justify-center">
        <div class="col-xs-12 col-md-10">
      <div class="text-h3 text-bold text-blue-4"> {{$t('profcheck.suggest_a_prof_heading')}} </div>
      <div class="text-body1 q-mt-lg">
        {{$t('profcheck.suggest_a_prof_subheading')}}
      </div>
        </div>
    </div>

    <q-form @submit="onSubmit" @reset="onReset">
      <div class="form-group">
        <label for="lname"> {{$t('profcheck.prof_surname')}} </label>
        <q-input v-model="lname" id="lname" outlined></q-input>
      </div>
      <div class="form-group">
        <label for="fname"> {{$t('profcheck.prof_firstname')}} </label>
        <q-input v-model="fname" id="fname" outlined></q-input>
      </div>
      <div class="form-group">
        <label for="subject"> {{$t('profcheck.prof_subject')}} </label>
        <q-input v-model="subject" id="subject" outlined></q-input>
      </div>
    </q-form>

    <div class="send-button">
      <q-btn
        @click="onSubmit"
        color="primary"
        :label="$t('common.send_button')"
        :disabled="!lname || !fname || !subject"
      >
      </q-btn>
    </div>
  </div>
</template>

<script>
import ProfessorSearch from "../components/profcheck/ProfessorSearch.vue";
export default {
  components: { ProfessorSearch },
  data() {
    return {
      lname: "",
      fname: "",
      subject: "",
      isLoading: false,
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Caprasimo&family=Poppins&display=swap");

#profcheck-logo {
  position: absolute;
  top: 15;
  right: 0;
  height: 55vw;
  z-index: -1;
}

.title {
  width: fit-content;
  height: fit-content;
  margin-left: 10%;
  margin-right: 40%;
  margin-bottom: 2%;
  margin-top: 10%;
}

.title h1 {
  color: #5bbdf4;
  font-family: "Poppins", sans-serif;
  font-size: 5em;
  font-weight: 600;
}

.title p {
  font-family: "Poppins", sans-serif;
}

form {
  margin-left: 10%;
  margin-top: 2%;
  display: grid;
  grid-gap: 50px;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(auto-fit, minmax(300px, 0.5fr));
  height: 100%;
  margin-right: 50%;
}

.form-group {
  display: flex;
  flex-direction: column;
  row-gap: 0.5em;
  color: #5bbdf4;
  font-family: "Poppins", sans-serif;
  font-size: max(1.5vw, 1.5em);
}

.form-group input {
  border: solid 1.3px #5bbdf4;
  border-radius: 3.78px;
  padding: 0.5em;
}

.send-button {
  margin-left: 10%;
  margin-top: 3%;
  margin-right: 10%;
}

.send-button button {
  background-color: #5bbdf4;
  color: white;
  border: none;
  padding-left: 1.2%;
  padding-right: 1.2%;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  border-radius: 4.78px;
  font-size: max(1vw, 1em);
  cursor: pointer;
}
</style>
